@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.AboutUs-Container{
    margin-bottom: 2rem;
}

.AboutUs-Sub-Container{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.AboutUs-Sub-Container-Image-Container{
    width: 100%;
}

.AboutUs-Sub-Container-Image{
    min-height: 300px;
    min-width: 250px;
    max-height: 300px;
    max-width: 250px;
    margin: auto;
    overflow: hidden;
}

.AboutUs-Sub-Container-Writeup{
    width: 100%;
    margin-top: 1rem;
}

.About-Us-Founder-Photo{
    width: 100%;
    object-fit: cover;
}

.AboutUs-Sub-Container-Flexbox{
    display: flex;
    flex-direction: column;
}

.AboutUs-Header{
    font-family: 'Merriweather', serif;
    text-align: left;
    color: var(--color5);
    border-bottom: 2px solid var(--color2);
}

.AboutUs-Writeup{
    text-align: justify;
    font-size: 16px;
}

/**
Header
**/

.AboutUs-Header-Sub-Container-Image{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
    max-height: 200px;
    object-fit: cover;
}

/**
Mobile
**/

@media only screen and (min-width: 600px){
    .AboutUs-Sub-Container-Writeup{
        width: 100%;
        margin-top: 0;
    }
    .AboutUs-Sub-Container-Image-Container{
        width: 280px;
        margin: auto;
    }
    .AboutUs-Sub-Container{
        width: 85%;
    }
    .AboutUs-Sub-Container-Flexbox{
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px){
    .AboutUs-Sub-Container{
        width: 65%;
    }
    .AboutUs-Sub-Container-Image{
        margin: 0;
    }
    .AboutUs-Sub-Container-Founder{
        flex-direction: row;
        display: flex;
    }
    .AboutUs-Sub-Container-Writeup{
        margin-left: 1rem;
    }
}

@media only screen and (min-width: 1200px){
    
}