/**
Top Header
**/

.page-Header{
    background-color: var(--color1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.page-Header-Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5%;
    max-height: 100px;
}

/**
Container for Logo & Company Name
**/

.companyinfo-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

/**
Combined Logo and Company Name
**/

.companyinfo-image{
    height: 150px;
    outline-width: 0;
}

.companyinfo-image:focus{
    outline: none;
}

/**
Navigation Bar
**/

.Navigation-Bar-List{
    list-style: none;
    display: none;
}

.Navigation-Bar-List-Item{
    margin: 0 0.5rem;
    padding: 0.5rem;
    text-decoration: none;
    color: black;
}

.Navigation-Bar-List-Item:hover{
    color: red;
}

.Active-Navigation-Bar-List-Item{
    border-bottom: 1px solid black;
    margin: 0 0.5rem;
    padding: 0.5rem;
    text-decoration: none;
    color: black;
}

/**
Mobile Navigation Bar
**/

.Mobile-Navigation-Bar-Button{
    font-size: 2rem;
    margin-right: 10px;
}

.Mobile-Navigation-Bar-Button:hover{
    cursor: pointer;
}

.Mobile-Navigation-Bar-Button-Close{
    position: fixed;
    right: 0;
    top: 0;
    font-size: 2.5rem;
}

.Mobile-Navigation-Menu{
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 2;
    position: fixed;
    background-color:  var(--color1);
    color: red;
}

.Mobile-Navigation-Menu-List{
    list-style: none;
}

.Mobile-Navigation-Menu-List-Item{
    text-decoration: none;
    color: black;
    margin: 1.5rem 1.5rem 0 -0.5rem;
    font-size: 2rem;
    display: block;
}

/**
Footer
**/

.footer-container{
    background-color: var(--color1);
    font-size: 12px;
    padding: 0.5rem 0;
}

.Animation-Slide-Bottom{
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
     0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/**
Mobile
**/

@media only screen and (min-width: 600px){
    .Mobile-Navigation-Bar-Button{
        display: flex;
    }
}

@media only screen and (min-width: 992px){
    .Navigation-Bar-List{
        display: flex;
    }
    .Mobile-Navigation-Bar-Button{
        display: none;
    }
    .page-Header-Container{
        width: 70%;
        margin: auto;
    }
}