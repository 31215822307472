/**
Homepage Container
**/

.homepage-container{
}

/**
Homepage Carousel Banner
**/

.carousel-banner-container{
    margin-top: 5rem;
    max-height: 600px;
    margin: auto;
}

.carousel-banner-container img{
    display: block;
}

.active-carousel-banner img{
    display: block;
    width: 100%;
}

.inactive-carousel-banner img{
    opacity: 0;
    width: 100%;
    height: 0;
}

/**
Homepage Banner Display
**/

.Banner-Display-Container{
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
    z-index: 1;
    position: relative;
}

.Banner-Display-Image{
    display: none;
    object-fit: cover;
    width: 100%;
    max-height: 200px;
}

.Banner-Display-Image-Mobile{
    object-fit: cover;
    width: 100%;
    max-height: 200px;
    display: block;
}

/**
Homepage Skinny Banner
**/
.skinny-banner-container{
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.skinny-banner-sub-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    width: 80%;
    margin-top: 2rem;
}

.skinny-banner-box{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    margin-bottom: 1rem;
}

.skinny-banner-box-text{
    width: 80%;
    padding-bottom: 1rem;
    margin: auto;
}

.skinny-banner-box-header{
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/**
Contact Us Details
**/

.Contact-Us-Container{
    padding-top: 3rem;
    background-color: var(--color1);
    padding-bottom: 3rem;
    z-index: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.Contact-Us-Sub-Container{
    width: 80%;
    margin: auto;
}

.Contact-Us-Write-Up{
    margin-top: 3rem;
}

/**
Packages
**/
.Packages-Container{
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.Packages-Sub-Container{
    display: flex;
    width: 90%;
    margin: auto;
    flex-direction: column;
    flex-wrap: wrap;
}

.Package-Card-Container{
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 1rem 0.5% 0rem 0.5%;
}

.Package-Card-Container-Image{
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.Package-Card-Container a, .Package-Card-Container:visited{
    text-decoration: none;
    color: black;
}

.Package-Card-Details, .Package-Card-Details-2{
    width: 100%;
    display: flex;
    margin-left: 10px;
}

.Package-Card-Details-Name{
    font-size: 1.2rem;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
}

/**
Mobile
**/

@media only screen and (min-width: 600px){
    .Banner-Display-Image{
        display: block;
        max-height: 400px;
        object-fit: cover;
        object-position: 0 10%  ;
    }
    .Banner-Display-Image-Mobile{
        display: none;
    }
    .skinny-banner-sub-container{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        width: 70%;
    }
    .skinny-banner-box-text{
        width: 70%;
        padding-bottom: 1rem;
        margin: auto;
    }

    .Packages-Sub-Container{
        width: 80%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .Package-Card-Container{
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (min-width: 992px){
    .Navigation-Bar-List{
        display: flex;
    }
    .Mobile-Navigation-Bar-Button{
        display: none;
    }
    .Contact-Us-Sub-Container{
        width: 50%;
        margin: auto;
    }
    .skinny-banner-sub-container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
        margin: auto;
        margin-top: 1rem;
    }
    .skinny-banner-box{
        width: 30%;
    }
    .Package-Card-Container{
        width: 49%;
    }
}

@media only screen and (min-width: 1200px){
    .Package-Card-Container{
        width: 30%;
    }
}