@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.ContactUs-Container{
    margin-bottom: 2rem;
}

.ContactUs-Sub-Container{
    width: 70%;
    margin: auto;
}

.ContactUs-Font{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    color: var(--color5);
}

.ContactUs-20px{
    font-size: 20px;
    margin-top: -20px;
    color: var(--color6);
}

.ContactUs-Box-Container{
    display: flex;
    width: 50%;
    justify-content: space-between;

}

.ContactUs-Header{
    font-size: 30px;
}

.ContactUs-Writeup{
}