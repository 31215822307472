:root{
  --color1: #E9E1DA;
  --color2: #AEC5D8;
  --color3: #D9BBB8;
  --color4: #C2ACB4;
  --color5: #0C4876;
  --color6: #608FB7;
}

.App {
  text-align: center;
  min-width: 350px;
}

.Container-Header{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
}

.Container-Sub-Header{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-style: italic;
    font-size: 1rem;
    margin-top: -0.8rem;
    font-weight: 500;
}