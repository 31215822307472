@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.Services-Page-Container{
}

.Services-Header-Container{
    display: flex;
    flex-direction: column;
}

.Services-Header-Sub-Container{
    background-color: white;
}

.Services-Header-Sub-Container-Image{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
    max-height: 200px;
    object-fit: cover;
}

.Services-Header-Sub-Container-Image-Overlay{
    background: rgba(50, 70, 80, 0.3);
    position: absolute;
    width: 100%;
    top: 100px;
    height: 200px;
    min-width: 350px;
}

.Services-Header-Sub-Container-Image-Overlay-Text{
    color: white;
    font-family: 'Merriweather', serif;
    font-size: 24px;
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px #000000;
    flex-direction: column;
}

.Services-Header-Sub-Container-Image-Overlay-Subtext{
    font-size: 18px;
}

/**
    Service Overall Page 
**/

.Service-Page-Container{
    width: 80%;
    margin: auto;
}

.Service-Page-Header{
    display: flex;
}


/**
    Image Gallery 
**/

.Services-Page-Image-Gallery{
    height: 400px;
}

.Service-Page-Carousel-Container{
    overflow: hidden;
    position: relative;
}

.Service-Page-Carousel-Image-Container{
    white-space: nowrap;
    transition: transform 0.3s;
}

.Service-Page-Carousel-Container-Image{
    height: 350px;
    width: 100%;
    object-fit: cover;
    display: inline-block;
}

.Service-Page-Carousel-Indicators{
    height: 15px;
    width: 15px;
    margin: 10px 10px;
    background-color: var(--color1);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.active-indicator{
    background-color: var(--color2);
}

.Service-Page-Carousel-Right-Button, .Service-Page-Carousel-Left-Button{
    position: absolute;
    top: 38%;
    font-size: 50px;
    cursor: pointer;
    color: var(--color1);
    width: auto;
    display: inline-block;
    -webkit-text-stroke: 1.5px white;
}

.Service-Page-Carousel-Right-Button{
    right: 10px;
}

.Service-Page-Carousel-Left-Button{
    left: 10px;
}

/**
    Service Description 
**/

.Services-Description-Container-Header{
    color: var(--color5);
    text-align: left;
    font-size: 20px;
}

.Services-Description-Container-Content p{
    text-align: justify;
}


/**
    Service Recycled Contents 
**/

.Services-Page-Recycle-Sub-Container-Sub-Header{
    display: flex;
    text-align: left;
    font-family: 'Merriweather', serif;
    font-size: 18px;
    color: var(--color5);
}

.Services-Page-Recycle-Sub-Container-Content{
    text-align: left;
}

.Services-Page-Recycle-Sub-Container-Content li{
    color: var(--color5);
}

.Services-Page-Recycle-Sub-Container-Content span{
    color: black;
}


/**
    Service Price Table 
**/

.Services-Page-Price-Container{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Services-Page-Price-Container-Table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin: auto;
}

.Services-Page-Price-Container-Table td{
    border: 1.5px solid var(--color5);
    padding: 10px 0 10px 0;
}

.Services-Page-Price-Container-Table-Left{
    width: 40%;
    font-weight: 600;
}

.Services-Page-Price-Container-Table-Right{
    background-color: var(--color2);
    font-weight: bold;
}

/**
Mobile
**/

@media only screen and (min-width: 600px){

}

@media only screen and (min-width: 992px){
    .Service-Page-Container{
        width: 65%;
    }    
}

@media only screen and (min-width: 1200px){

}